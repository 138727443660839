import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/SEO"
import { PageSegment, TitleAndDescription } from "../components"
import { GridOneColumn } from "../styles"
import { ContactUsForm } from "../components/homePage/ContactUs"

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
    height: 600px;
  }
`
const FormContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    max-width: 640px;
  }
`

const TextContainer = styled.div`
  max-width: 80%;
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.2em;

  @media (min-width: 768px) {
    font-size: 1.5em;
    text-align: left;
    margin-left: 20px;
  }
`
export const Contact = () => {
  return (
    <>
      <SEO title="Contact" />
      <PageSegment background="var(--white)">
        <GridOneColumn>
          <TitleAndDescription title="Let's stay in touch" rightSide={true}>
            <p>
              If you’re interested in working with us or our services drop us an
              email!
            </p>
          </TitleAndDescription>
          <FlexContainer>
            <TextContainer>
              <p>You can find us at:</p>
              <p>301 Goldhawk Road W12 8EU London, UK</p>
            </TextContainer>
            <FormContainer>
              <ContactUsForm />
            </FormContainer>
          </FlexContainer>
        </GridOneColumn>
      </PageSegment>
    </>
  )
}

export default Contact
